<div>
    <div class="page_header">
        <h1 class="page_title">Q & A</h1>
    </div>
    <section class="search_section">
        <mat-card>
            <form [formGroup]="searchForm" (ngSubmit)="SearchRequest()">
                <mat-card-content>
                    <div class="search_container">
                        <div>
                            <div class="search_tile">
                                <span class="search_title">Question</span>
                                <!-- 언제 부터 언제 까지 휴가 검색 -->
                                <mat-form-field appearance="fill">
                                    <mat-label>Question</mat-label>
                                    <input matInput formControlName="question" />
                                </mat-form-field>
                            </div>
                        </div>
                        <div>
                            <div class="search_tile">
                                <span class="search_title">Answered</span>

                                <mat-form-field appearance="fill">
                                    <mat-label>Category</mat-label>
                                    <mat-select [(value)]="selected">
                                        <mat-option value="all">All</mat-option>
                                        <mat-option value="not answered">Not Answered</mat-option>
                                        <mat-option value="answered">Answered</mat-option>

                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions align="end">
                    <button mat-flat-button color="primary" type="submit">SEARCH</button>
                </mat-card-actions>
            </form>
        </mat-card>
    </section>

    <section class="table_section mat-elevation-z1">
        <div class="example-table-container">
            <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
                <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
                    API rate limit has been reached. It will be reset in one minute.
                </div>
            </div>
            <div class="example-table-container">
                <table mat-table [dataSource]="inquiry_list" class="" matSort matSortActive="createdAt"
                    matSortDisableClear matSortDirection="desc">
                    <!-- createdAt -->
                    <ng-container matColumnDef="createdAt">
                        <th mat-header-cell *matHeaderCellDef disableClear mat-sort-header>
                            Created Date
                        </th>
                        <td mat-cell *matCellDef="let row">{{row.createdAt | date }}
                        </td>
                    </ng-container>


                    <!-- title -->
                    <ng-container matColumnDef="question">
                        <th mat-header-cell *matHeaderCellDef>Question</th>
                        <td mat-cell *matCellDef="let row">
                            {{row.question}}
                        </td>
                    </ng-container>


                    <!-- answered -->
                    <ng-container matColumnDef="answered">
                        <th mat-header-cell *matHeaderCellDef>answered</th>
                        <td mat-cell *matCellDef="let row">
                            <mat-icon [ngClass]="row.answer ? 'answer_checked' : 'non_answer' ">check</mat-icon>
                        </td>
                    </ng-container>


                    <!-- detail -->
                    <ng-container matColumnDef="detail">
                        <th mat-header-cell *matHeaderCellDef>Detail</th>
                        <td mat-cell *matCellDef="let row">
                            <button (click)="openDetailDialog(row)" mat-flat-button color="primary">detail</button>
                        </td>
                    </ng-container>


                    <!-- delete -->
                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef>Delete</th>
                        <td mat-cell *matCellDef="let row">
                            <button (click)="deleteItem(row._id)" mat-flat-button color="warn"
                                [disabled]="row.answer ? true : false">delete</button>
                        </td>
                    </ng-container>
                    <!-- detail -->
                    <!-- <ng-container matColumnDef="note">
                        <th mat-header-cell *matHeaderCellDef>Note</th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row.updator">Revised in {{row.updatedAt | date}}</span>
                        </td>
                    </ng-container> -->

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                    <!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="5">No data</td>
                    </tr>
                </table>
            </div>
            <mat-paginator [length]="resultsLength" (page)="handlePageEvent()" [pageSize]="10"
                [pageSizeOptions]="[10, 30, 50]" aria-label="Select page of GitHub search results"></mat-paginator>
        </div>
    </section>
</div>