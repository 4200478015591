import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map, merge, startWith, switchMap } from 'rxjs';
import { UserProfileData } from 'src/app/interfaces/user-profile-data.interface';
import { MaterialsModule } from 'src/app/materials/materials.module';
import { ChatService } from 'src/app/services/chat/chat.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ProfileService } from 'src/app/stores/profile/profile.service';
import { DetailComponent } from './detail/detail.component';
import { MatDialog } from '@angular/material/dialog';
interface FormData {
  question: FormControl;

}
@Component({
  standalone: true,
  imports: [CommonModule, MaterialsModule],
  selector: 'app-qna',
  templateUrl: './qna.component.html',
  styleUrls: ['./qna.component.scss']
})
export class QnaComponent {
  userId: any = undefined; // 사용자 구별에 사용할 변수
  inquiry_list: any = undefined; // 질문 요청 리스트

  displayedColumns: string[] = ['createdAt', 'question', 'answered', 'detail', 'delete'];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  searchForm: FormGroup = new FormGroup<FormData>({
    question: new FormControl(''),
  })



  selected = 'all';


  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  userProfileData: UserProfileData | undefined; // 유저 프로필 정보
  userProfile$ = toObservable(this.profileService.userProfile); // 유저 프로필 정보 변경을 모니터링 하기 위한 observable
  constructor(
    private chatService: ChatService,
    private profileService: ProfileService,
    private dialogService: DialogService,
    public dialog: MatDialog,
  ) {

  }


  getData() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.chatService.inquiry_lists_by_user(this.userId, this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize, this.searchForm.value.question, this.selected).pipe()
        }),
        map((data: any) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          this.resultsLength = data.total_count;

          return data.items;
        }),
      )
      .subscribe(data => (
        this.inquiry_list = data
      ));
  }

  handlePageEvent() {
    this.getData()
  }
  SearchRequest() {
    this.getData();
  }
  ngAfterViewInit() {
    this.userProfile$.subscribe(async () => {
      this.userProfileData =
        this.profileService.userProfile().profileData?.user;
      this.userId = this.userProfileData?._id; // 유저 아이디 받아오기
      if (this.userId) { // 유저의 값을 받아왔다면 원하는 정보 받기 시도 (처음에는 undefined)
        this.getData();
      }
    })
  }



  /**
   * 질문 삭제
   * @param _id 해당 아이템의 _id
   */
  deleteItem(_id: string) {
    this.dialogService.openDialogConfirm('Are you sure?').subscribe((confirm: any) => {
      if (confirm) {
        this.chatService.delete_inquire(_id).subscribe((res: any) => {
          if (res.status) {
            this.getData()
          }
        })
      }
    })
  }

  /**
   * 자세히 보기 다이어로그 열기, 닫기 함수
   */
  openDetailDialog(data: any) {
    const dialogRef = this.dialog.open(DetailComponent,
      {
        data,
        width: '100%',
        maxWidth: '1200px'
      },
    );

    return dialogRef.afterClosed().subscribe((result: any) => {
      this.getData()
    });
  }
}
