import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-expense-reason-dialog',
    templateUrl: './expense-reason-dialog.component.html',
    styleUrls: ['./expense-reason-dialog.component.scss']
})
export class ExpenseReasonDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ExpenseReasonDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { reason: string, status: string }
    ) {
        console.log(this.data)
    }
}