<mat-toolbar color="primary" class="toolbar mat-elevation-z6">
  <button (click)="openSidenav()" mat-icon-button fxHide.gt-md>
    <mat-icon>menu</mat-icon>
  </button>
  <span>{{ userProfileData?.company }}</span>
  <span style="flex: 1"></span>

  <div style="display: flex; align-items: center; gap: 10px">
    <button
      mat-icon-button
      [matMenuTriggerFor]="notification"
      (click)="AllRead()"
    >
      <div *ngIf="alertInfo?.count > 0">
        <mat-icon [matBadge]="alertInfo?.count" matBadgeColor="accent"
          >notifications</mat-icon
        >
      </div>
      <div *ngIf="alertInfo?.count == 0">
        <mat-icon matBadgeColor="accent">notifications</mat-icon>
      </div>
    </button>
    <button mat-button [matMenuTriggerFor]="profile">
      <div class="profile_button">
        <img
          class="profile_image"
          [src]="userProfileData?.profileImgPath"
          alt=""
        />
        <span>{{ userProfileData?.username }}</span>
      </div>
    </button>
  </div>

  <!-- 알람 부분 -->
  <mat-menu #notification xPosition="before" class="my-class">
    <mat-card style="width: 400px">
      <mat-card-header>
        <mat-card-title>Alarm</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div style="max-height: 720px; overflow: auto">
          <h3 class="sub_header">Today Alarm</h3>
          <mat-list role="list">
            <div *ngFor="let info of alertInfo?.todayInfo">
              <mat-list-item
                role="listitem"
                (click)="clickAlert(info)"
                class="alert_list_item"
              >
                <mat-icon matListItemIcon>{{ info.iconText }}</mat-icon>
                <div matListItemTitle class="alert_list_item_text">
                  {{ info.alertLabel }}
                </div>
                <div matListItemLine>{{ info.createdAt | date : "short" }}</div>
              </mat-list-item>
            </div>
            <div
              class="no_notification"
              *ngIf="alertInfo?.todayInfo.length == 0"
            >
              There is no alarm
            </div>
          </mat-list>
          <h3 class="sub_header">Previous Alarm</h3>
          <mat-list role="list">
            <div *ngFor="let info of alertInfo?.info">
              <mat-list-item
                role="listitem"
                (click)="clickAlert(info)"
                class="alert_list_item"
              >
                <mat-icon matListItemIcon>{{ info.iconText }}</mat-icon>
                <div matListItemTitle class="alert_list_item_text">
                  {{ info.alertLabel }}
                </div>
                <div matListItemLine>{{ info.createdAt | date : "short" }}</div>
              </mat-list-item>
            </div>
            <div class="no_notification" *ngIf="alertInfo?.info.length == 0">
              There is no alarm
            </div>
          </mat-list>
        </div>
      </mat-card-content>
      <!-- <mat-card-actions style="display: flex; flex-direction: column;">
                <button (click)="clickAllRead()" mat-flat-button color="primary">MARK ALL AS READ</button>
            </mat-card-actions> -->
    </mat-card>
  </mat-menu>

  <!-- 로그아웃 메뉴 부분 -->
  <mat-menu #profile>
    <button mat-menu-item [routerLink]="['/profile']">
      <mat-icon>info</mat-icon>
      <span>About</span>
    </button>
    <button mat-menu-item (click)="signOut()">
      <mat-icon>logout</mat-icon>
      <span>Sign out</span>
    </button>
  </mat-menu>
</mat-toolbar>
