import { CommonModule } from '@angular/common';
import { Component, ElementRef, NgZone, ViewChild, ViewEncapsulation } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { UserProfileData } from 'src/app/interfaces/user-profile-data.interface';
import { MaterialsModule } from 'src/app/materials/materials.module';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ChatService } from 'src/app/services/chat/chat.service';
import { ProfileService } from 'src/app/stores/profile/profile.service';
import * as moment from 'moment';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { RequestDialogComponent } from './request-dialog/request-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
@Component({
  standalone: true,
  imports: [CommonModule, MaterialsModule, NgxTypedJsModule],
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatComponent {
  history: Array<any> = []; // 대화 내역 저장용 
  chatHistory: Array<any> = [];
  question: string = ''; // 질문 
  waiting: boolean = false;
  maxRows: number = 8;

  userId: any = '';
  companyId: any = '';
  username: any = '';
  year: any = '';

  @ViewChild('textarea') textarea!: ElementRef<HTMLTextAreaElement>;
  userProfileData: UserProfileData | undefined;
  userProfile$ = toObservable(this.profileService.userProfile);
  constructor(
    private chatService: ChatService,
    private authService: AuthService,
    private profileService: ProfileService,
    public dialog: MatDialog,
    private ngZone: NgZone,
    private router: Router,
    private dashboardService: DashboardService
  ) {
    this.userProfile$.subscribe(async () => {
      this.userProfileData = this.profileService.userProfile().profileData?.user;
      console.log(this.userProfileData)
      this.userId = this.userProfileData?._id;
      this.username = this.userProfileData?.username;
      this.companyId = this.userProfileData?.companyId;
      this.year = this.userProfileData?.year;

      console.log(this.companyId)
      if (this.userId) {
        this.chatService.getHistory(this.userId).subscribe((res: any) => {
          if (res) {
            console.log(res)
            res.map((history: any) => {
              this.history.push({ type: 'question', text: history.question });
              this.history.push({ type: 'answer', text: history.answer });
              this.chatHistory.push(history);
            })
          }
        })
      }
    });
  }
  myLeaveStatus: any;

  ngOnInit(): void {
    if (!this.authService.getTokenInfo().isManager) {
      this.dashboardService.employeeDashboard().subscribe((res: any) => {
        this.myLeaveStatus = res.myLeaveStatus;




      });
    }
  }

  @ViewChild('history_absolute', { static: true }) private chat_history_absolute!: ElementRef;
  private resizeObserver!: ResizeObserver;
  ngAfterViewInit(): void {


    this.resizeObserver = new ResizeObserver(entries => {
      this.ngZone.run(() => {
        entries.forEach(entry => {
          const container = this.chat_history_absolute.nativeElement;

          container.scrollTop = container.scrollHeight;
        });
      });
    });

    this.resizeObserver.observe(this.chat_history_absolute.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  excerpts: Array<any> = []; // 대화를 도출하는데 사용된 데이터 목록
  referenceIndex: Array<any> = []; // 결과작성에 실제 활용된 excerpts index
  submit() {
    if (this.waiting) return;

    this.waiting = true;
    const temp = [];
    this.chatService.ask(this.question, this.chatHistory, this.userId, this.companyId, this.username, this.year, this.myLeaveStatus).subscribe((res: any) => {
      this.referenceIndex = res.answer.index;

      if (res.status) {
        let tmpStr = `${res.answer.text} `;


        this.history.push({ type: 'answer', text: tmpStr });

        temp.push(res.answer.text)
      }
      this.excerpts = res.excerpts;

      this.waiting = false;


    })
    this.history.push({ type: 'question', text: this.question });
    temp.push(this.question);

    this.question = '';
    this.chatHistory.push(temp);
  }


  handleKeyDown(event: KeyboardEvent) {
    const textarea = this.textarea.nativeElement;

    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.submit()
    } else if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      this.insertAtCursor('\n');
    }

    if (textarea.scrollHeight < 216) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }

  insertAtCursor(value: string) {
    const textarea = this.textarea.nativeElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    textarea.value = textarea.value.substring(0, start) + value + textarea.value.substring(end);
    textarea.selectionStart = textarea.selectionEnd = start + value.length;
  }


  deleteChatHistory() {
    this.chatService.deleteHistory(this.userId).subscribe((res: any) => {
      if (res.status) {
        this.history = [];
        this.chatHistory = [];
      }
    })
  }

  /**
   * 질문 요청 다이어로그 열기 함수
   */
  openRequestDialog() {

    const dialogRef = this.dialog.open(RequestDialogComponent, {
      data: {
        history: this.history,
        company: this.companyId,
        requester: this.userId
      },
      maxWidth: '1200px',
      width: '100%'
      // disableClose: true,

    });

    return dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.deleteChatHistory()
        this.router.navigate(['qna'])
      }
    });
  }
}
