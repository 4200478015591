<div class="container">
    <h2>Are you unsatisfied with the response?</h2>
    <mat-dialog-content style="padding: 0px !important;">
        <p>You can ask the administrator directly.</p>
        <mat-form-field style="width: 100%; display: flex;">
            <mat-label>Content</mat-label>
            <textarea class="RequestTextarea" rows="12" matInput [(ngModel)]="content" name="content"></textarea>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button (click)="onCancelClick(false)">Cancel</button>
        <button mat-button (click)="submit()">Submit</button>
    </mat-dialog-actions>
</div>