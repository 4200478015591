<div class="container">
    <section class="sections">
        <h3>Chat History</h3>
        <div class="chat_history" style="min-height: 300px; max-height: 500px; overflow-y: auto;">
            <div class="chat_history_absolute">
                <div *ngFor="let chat of data.history">
                    <div *ngIf="chat.type == 'question'">
                        <article class="question" style="white-space: pre-line;">
                            <div>
                                {{chat.text}}
                            </div>
                        </article>
                    </div>

                    <!-- Answer-->
                    <div *ngIf="chat.type == 'answer'">
                        <article class="answer" style="white-space: pre-line;">
                            <div>
                                {{chat.text}}
                            </div>

                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="sections">
        <div style="display: flex; align-items: center; gap: 0.5rem; margin-bottom: 16px;">
            <h3 style="margin: 0;">Inquiry</h3>
            <mat-icon (click)="toggleEditMode()">edit</mat-icon>
        </div>

        <div *ngIf="!editMode" class="section_content" readonly>{{data.question}}</div>
        <div *ngIf="editMode" class="section_content">
            <textarea rows="12" [(ngModel)]="question"></textarea>
            <button mat-flat-button color="primary" (click)="submit()">
                edit
            </button>
        </div>
    </section>


    <section class="sections">
        <h3>Response</h3>
        <div class="section_content" readonly>{{data.answer}}</div>
        <!-- <div>
            <button mat-flat-button type="button">Send Response</button>
        </div> -->

    </section>
</div>