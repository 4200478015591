<h2 mat-dialog-title>{{ data.status === 'read' ? 'View Reason' : 'Enter Reason' }}</h2>
<mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Reason</mat-label>
        <textarea matInput [(ngModel)]="data.reason" [readonly]="data.status === 'read'">
        </textarea>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="data.reason" [disabled]="data.status === 'read'">
        Save
    </button>
</mat-dialog-actions>