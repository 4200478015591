import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  // 질문
  ask(question: string, history: Array<string>, userId: string, company: string, username?: string, year?: string, myLeaveStatus?: any) {
    return this.http.post(this.baseUrl + `/chat/${company}`, { question, history, userId, company, username, year, myLeaveStatus });
  }


  getHistory(userId: string) {
    return this.http.get(this.baseUrl + '/chat/getHistory/' + userId)
  }

  deleteHistory(userId: string) {
    return this.http.delete(this.baseUrl + '/chat/deleteHistory/' + userId)
  }


  // 관리자에게 직접 질문
  ask_to_admin(question: string, history: Array<any>, company: string, requester: string) {
    return this.http.post(this.baseUrl + `/inquire/${company}/createRequire`, { question, history, requester })
  }

  // user에게 해당하는 요청 목록
  inquiry_lists_by_user(requester: string,
    sort: string,
    order: SortDirection,
    page: number,
    pageSize: number,
    question: string,
    selected: string
  ) {
    return this.http.get(this.baseUrl + `/inquire/inquireList/${requester}?sort=${sort}&order=${order}&page=${page + 1}&pageSize=${pageSize}&question=${question}&selected=${selected}`);
  }

  // 질문자 질문 삭제 요청
  delete_inquire(_id: string) {
    return this.http.delete(this.baseUrl + `/inquire/deleteRequire/${_id}`)
  }

  // 질문자 질문 수정
  edit_inquire(_id: string, question: string) {
    return this.http.patch(this.baseUrl + `/inquire/editRequire/${_id}`, { question })
  }
}
