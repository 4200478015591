<div class="chat_container">
    <section class="history_section">
        <div class="history_absolute" id="history_ab">
            <div #history_absolute style="display: flex;
            flex-direction: column;
            gap: 0.5rem;">
                <ng-container *ngFor="let chat of history; index as i">
                    <!-- <div [innerHTML]="chat" *ngFor="let chat of history"></div> -->
                    <!-- Question -->
                    <div *ngIf="chat.type == 'question'">
                        <article class="question" style="white-space: pre-line;">
                            <div>
                                {{chat.text}}
                            </div>
                        </article>
                    </div>

                    <!-- Answer-->
                    <div *ngIf="chat.type == 'answer' && i < history.length-1">
                        <article class="answer" style="white-space: pre-line;">
                            <div>
                                {{chat.text}}
                            </div>

                        </article>
                    </div>


                    <!-- 마지막 답변 animation -->
                    <ngx-typed-js [strings]="[chat.text]" [typeSpeed]="0" [showCursor]="false"
                        *ngIf="chat.type == 'answer' && i== history.length-1">
                        <article class="typing answer" style="white-space: pre-line;">


                        </article>

                    </ngx-typed-js>

                </ng-container>

                <!-- Animation -->

                <article class="chat-bubble" [hidden]="!waiting">
                    <div class="typing">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                </article>
            </div>

            <div class="history_bottom_button" *ngIf="history.length >0">
                <p>Didn't get the answer you were looking for from the chatbot?</p>
                <button class="dialog_open_button" (click)="openRequestDialog()">Request assistance directly from a
                    representative.</button>
            </div>
        </div>
    </section>
    <section class="form_section">
        <div class="input_section">
            <form class="input_form" (ngSubmit)="submit()">
                <textarea #textarea rows="1" class="question_input" (keydown)="handleKeyDown($event)"
                    placeholder="Please enter your question" [(ngModel)]="question"
                    [ngModelOptions]="{standalone: true}"></textarea>
                <button [disabled]="waiting" type="submit"
                    class="submit_button"><mat-icon>arrow_upward</mat-icon></button>
            </form>
            <div>
                <button (click)="deleteChatHistory()" mat-flat-button color="primary">
                    <mat-icon class="delete_button">delete</mat-icon>reset
                </button>
            </div>
        </div>

    </section>
</div>