import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaterialsModule } from 'src/app/materials/materials.module';
import { ChatService } from 'src/app/services/chat/chat.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';

/**
 * 챗봇 답변 오류에 대한 신고 및 답변 요청 다이어로그 컴포넌트
 */
@Component({
  standalone: true,
  imports: [CommonModule, MaterialsModule],
  selector: 'app-request-dialog',
  templateUrl: './request-dialog.component.html',
  styleUrls: ['./request-dialog.component.scss']
})
export class RequestDialogComponent {
  content: any = '';

  constructor(
    public dialogRef: MatDialogRef<RequestDialogComponent>, // 다이어로그 컨트롤 
    @Inject(MAT_DIALOG_DATA) public data: any, // data를 상속 받아서 사용 (data는 history 사용)
    private chatService: ChatService,
    private dialogService: DialogService,

  ) {



  }

  /**
   * 다이어로그 닫기 함수
   */
  onCancelClick(result: any): void {
    this.dialogRef.close(result);
  }


  /**
   * 요청 함수
   */
  submit(): void {
    // 데이터베이스에 저장할 데이터 정리
    const data = {
      content: this.content, // 질문 사항
      history: this.data?.history, // gpt와 대화한 대화 내역
      company: this.data?.company, // 회사 정보
      requester: this.data?.requester // 요청자 정보
    }


    this.chatService.ask_to_admin(data.content, data.history, data.company, data.requester).subscribe((res: any) => {
      if (res.result == 'success') {
        this.dialogService.openDialogPositive('Your request has been processed successfully').subscribe((after: any) => {


          this.onCancelClick(true);

        })
      }
    })
  }

}
