import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaterialsModule } from 'src/app/materials/materials.module';
import { ChatService } from 'src/app/services/chat/chat.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';

@Component({
  standalone: true,
  imports: [CommonModule, MaterialsModule],
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent {
  constructor(
    public dialogRef: MatDialogRef<DetailComponent>, // 다이어로그 컨트롤 
    @Inject(MAT_DIALOG_DATA) public data: any, // data를 상속 받아서 사용 (data는 history 사용)
    private chatService: ChatService,
    private dialogService: DialogService
  ) {
    console.log(data)
    this.question = data.question
  }


  editMode: boolean = false;
  question: string = '';

  /**
   * 다이어로그 닫기 함수
   */
  onCancelClick(result: any): void {
    this.dialogRef.close(result);
  }


  toggleEditMode() {
    this.editMode = !this.editMode
  }


  submit() {
    this.chatService.edit_inquire(this.data._id, this.question).subscribe({
      next: (res: any) => {
        if (res.status) {
          this.dialogService.openDialogPositive('edit successfully').subscribe(() => {
            this.onCancelClick(true);
          })
        }

      },
      error: (err) => {
        this.dialogService.openDialogNegative(err).subscribe(() => {
          this.onCancelClick(false);
        })
      }
    })
  }
}
